import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useSingleDataset from '../../hooks/useSingleDataset';
import { dataTypes } from '../Shared/Enums';
import PSet from './PSet/PSet';
import ToxicoSet from './ToxicoSet/ToxicoSet';
import XevaSet from './XevaSet/XevaSet';
import ClinicalGenomics from './ClinicalGenomics/ClinicalGenomics';
import RadioSet from './RadioSet/RadioSet';
import StyledPage from '../../styles/StyledPage';
import RadiomicSet from './RadiomicSet/RadiomicSet';
import {
    LayoutContainer,
    StyledContainerOuter,
    StyledContainerInner
} from '../SearchRequest/RadiomicSet/Styles/StyledRadiomicSetSearch';
import * as MainStyle from '../Main/MainStyle';

const SingleDatasetNew = () => {
    const location = useLocation();
    const { datatype, id } = useParams();

    const { getDataset, getHeader, getGeneralInfoAccordion, datasetMessage, publishDialog, dataset } = useSingleDataset(
        datatype,
        `${id}`
    );

    useEffect(() => {
        const getData = async () => {
            console.log(location.search);
            await getDataset(location.search);
        };
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //If the dataset is not a legacy dataset, use the new DNL
    if (dataset.data.legacy === false) {
        const datasetTab = dataset.data.tabData.find(tab => tab.header === 'Dataset');
        // console.log(datasetTab.data);
        const disclaimerTab = dataset.data.tabData.find(tab => tab.header === 'Disclaimer');
        // console.log(disclaimerTab.data);
        const releaseTab = dataset.data.tabData.find(tab => tab.header === 'Release Notes');
        // console.log(releaseTab.data);
        return (
            <StyledPage>
                {dataset.ready && (
                    <>
                        {datasetMessage}
                        {getHeader()}
                        {publishDialog()}
                        {getGeneralInfoAccordion(dataset.data)}
                        <LayoutContainer>
                            <div className="content-row">
                                <StyledContainerOuter>
                                    <div class="card-container">
                                        <div class="card-title ">About The Dataset</div>
                                        <div className="hr-container">
                                            <hr className="hr-style" />
                                        </div>
                                        <ul className="list-style-card-main">
                                            <li>
                                                <span>Generated By:</span> BHK lab
                                            </li>
                                            <li>
                                                <span>Curated By:</span> BHK lab
                                            </li>
                                            <li>
                                                <span>Curated On:</span> Coming soon!
                                            </li>
                                            <li>
                                                <span>DOI:</span> Coming soon!
                                            </li>
                                            <li>
                                                <span>Format:</span> Coming soon!
                                            </li>
                                            <li>
                                                <span>Version:</span> {dataset.data.name}
                                            </li>
                                            <li>
                                                <span>Data Disclaimer: </span>
                                                {disclaimerTab.data.disclaimer}
                                            </li>
                                            <li>
                                                <span>Data Usage Policy: </span>
                                                {disclaimerTab.data.usagePolicy}
                                            </li>
                                            <li>
                                                <span>Please Cite the Following: </span>
                                                {disclaimerTab.data.citations.map((item, i) => (
                                                    <div key={item}>{item}</div>
                                                ))}
                                            </li>
                                            {datasetTab.data.usefulLinks > 0 && (
                                                <li>
                                                    <span>Useful Links:</span>
                                                    <ul className="list-style-card-sub">
                                                        <li>Link 1: https://github.com</li>
                                                        <li>Link 2: https://google.com</li>
                                                    </ul>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </StyledContainerOuter>

                                <StyledContainerInner>
                                    <div class="card-container">
                                        <div class="card-title ">RNA</div>
                                        <div className="hr-container">
                                            <hr className="hr-style" />
                                        </div>
                                        {datasetTab.data.rna.length > 0 && (
                                            <ul className="list-style-card-main">
                                                <li>
                                                    <span>Expression:</span>
                                                    <ul className="list-style-card-sub">
                                                        {console.log(datasetTab.data.rna)}
                                                        {datasetTab.data.rna.map((rna, i) => (
                                                            <li>
                                                                <a href={`${rna.url}`} target="_blank" rel="noreferrer">
                                                                    <span>{rna.name}: </span>
                                                                </a>
                                                                {`${rna.description}`}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            </ul>
                                        )}
                                    </div>

                                    {datasetTab.data.drugResponse.length > 0 && (
                                        <div class="card-container">
                                            <div class="card-title ">Drug Response</div>
                                            <div className="hr-container">
                                                <hr className="hr-style" />
                                            </div>
                                            <ul className="list-style-card-main">
                                                <li>
                                                    <span>Response:</span>
                                                    <ul className="list-style-card-sub">
                                                        {console.log(datasetTab.data.drugResponse)}
                                                        {datasetTab.data.drugResponse.map((drugResponse, i) => (
                                                            <li>
                                                                <a
                                                                    href={`${drugResponse.url}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <span>{drugResponse.name}: </span>
                                                                </a>
                                                                {`${drugResponse.description}`}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </StyledContainerInner>

                                <StyledContainerOuter>
                                    <div class="card-container">
                                        <div class="card-title ">Description</div>
                                        <div className="hr-container">
                                            <hr className="hr-style" />
                                        </div>
                                        {datasetTab.data.description}
                                    </div>
                                    <div class="card-container">
                                        <div class="card-title ">Pipeline Details</div>
                                        {
                                            <div className="hr-container">
                                                <hr className="hr-style" />
                                            </div>
                                            /* 
                                        <ul className="list-style-card-main">
                                            <li>
                                                <span>Pipeline:</span> https://github.com/bhklab/CCLE_2019
                                            </li>
                                            <li>
                                                <span>Docker Image:</span>{' '}
                                                https://hub.docker.com/r/minorunakano/orcestra-snakemake
                                            </li>
                                            <li>
                                                <span>{'Data Curation Script(s)'}:</span>
                                                <ul className="list-style-card-sub">
                                                    <li>Source 1: https://github.com/bhklab/script1</li>
                                                    <li>Source 2: https://github.com/bhklab/script2</li>
                                                    <li>Source 3: https://github.com/bhklab/script3</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <span>Processing Tools:</span> Kalisto, ...
                                            </li>
                                            <li>
                                                <span>Gencode Version:</span>GRCh37
                                            </li>
                                            <li>
                                                <span>Data Transformation:</span>?
                                            </li>
                                        </ul> */
                                        }
                                        <div
                                            style={{
                                                fontSize: '24px',
                                                fontWeight: '600',
                                                textAlign: 'center',
                                                width: '100%',
                                                marginTop: '20px',
                                                marginTop: '10px'
                                            }}
                                        >
                                            Pipeline Details Coming soon!
                                        </div>
                                    </div>
                                    <div class="card-container">
                                        <div class="card-title ">Release Notes</div>
                                        <div className="hr-container">
                                            <hr className="hr-style" />
                                        </div>
                                        <ul className="list-style-card-main">
                                            {releaseTab.data.releaseNotes.counts.length > 0 &&
                                                releaseTab.data.releaseNotes.counts.map((note, i) => (
                                                    <li key={note.name}>
                                                        <span>{note.name}:</span> {note.current} total{' '}
                                                        {note.name.toLowerCase()}
                                                    </li>
                                                ))}
                                            <li>
                                                <span>Primary Tumours:</span> Sarcoma (including soft tissue and bone
                                                subtypes)
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <div class="card-container">
                                        <div class="card-title ">Badges</div>
                                        <div className="hr-container">
                                            <hr className="hr-style" />
                                        </div>
                                        <ul className="list-style-card-main">
                                            <li>
                                                <span>Annotations Standards:</span> Lorem ipsum dolor sit amet
                                            </li>
                                            <li>
                                                <span>QC scripts or CO capsule:</span> https://codeocean.com
                                            </li>
                                        </ul>
                                    </div> */}
                                </StyledContainerOuter>
                            </div>
                        </LayoutContainer>
                    </>
                )}
                {!dataset.data && <h3>Dataset with the specified DOI could not be found</h3>}
            </StyledPage>
        );
    }
    // if the dataset is legacy, use the old format
    else {
        return (
            <StyledPage>
                {dataset.ready && (
                    <>
                        {datasetMessage}
                        {getHeader()}
                        {publishDialog()}
                        {getGeneralInfoAccordion(dataset.data)}
                        <>
                            {datatype === dataTypes.pharmacogenomics && <PSet dataset={dataset.data} />}
                            {datatype === dataTypes.toxicogenomics && <ToxicoSet dataset={dataset.data} />}
                            {datatype === dataTypes.xenographic && <XevaSet dataset={dataset.data} />}
                            {datatype === dataTypes.clinicalgenomics && <ClinicalGenomics dataset={dataset.data} />}
                            {datatype === dataTypes.radiogenomics && <RadioSet dataset={dataset.data} />}
                            {datatype === dataTypes.icb && <ClinicalGenomics dataset={dataset.data} />}
                            {datatype === dataTypes.radiomics && <RadiomicSet dataset={dataset.data} />}
                        </>
                    </>
                )}
                {!dataset.data && <h3>Dataset with the specified DOI could not be found</h3>}
            </StyledPage>
        );
    }
};

export default SingleDatasetNew;
